import React from 'react';
import {IndicatorValueWithIds} from '../../app/slices/indicatorValueSlice';
import {renderTableCellBody, TableCellGap} from '../table/Table';
import IndicatorLink from './IndicatorLink';

export interface IndicatorCellDataProps {
  label: string;
  month: IndicatorValueWithIds[];
  quarter: IndicatorValueWithIds[];
  total: IndicatorValueWithIds;
  searchUrl?: string | null;
}

export const IndicatorCellData: React.FC<IndicatorCellDataProps> = ({
  label,
  month,
  quarter,
  total,
  searchUrl,
}) => {
  const monthList = [label, ...month, total];

  return (
    <>
      {renderTableCellBody(
        monthList,
        (data: string | IndicatorValueWithIds) => {
          if (typeof data === 'string') return data;
          return (
            <IndicatorLink
              value={data.value}
              searchUrl={searchUrl}
              ids={data.ids}
            />
          );
        },
        false
      )}
      <TableCellGap />
      {renderTableCellBody(
        quarter,
        (data: IndicatorValueWithIds) => {
          return (
            <IndicatorLink
              value={data.value}
              searchUrl={searchUrl}
              ids={data.ids}
            />
          );
        },
        false
      )}
    </>
  );
};
