import {createSlice, createAsyncThunk, SerializedError} from '@reduxjs/toolkit';

export interface AuthState {
  status: 'idle' | 'checking' | 'succeeded' | 'failed';
  error: SerializedError | null;
}

const initialState: AuthState = {
  status: 'idle',
  error: null,
};

export const checkAuth = createAsyncThunk('auth/checkAuth', async () => {
  const authToken = document.cookie
    .split(';')
    .find(cookie => cookie.trim().startsWith('authToken='));
  return authToken !== undefined;
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(checkAuth.pending, state => {
        state.status = 'checking';
        state.error = null;
      })
      .addCase(checkAuth.fulfilled, (state, action) => {
        state.status = action.payload ? 'succeeded' : 'failed';
        state.error = null;
      })
      .addCase(checkAuth.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      });
  },
});

export default authSlice.reducer;
