import {getWebApi} from './web-api-access';
import {getConfig} from '../../config';

const {insightsApiUrl} = getConfig();

export type IndicatorValue = {
  term: string;
  value: number;
};

export type IndicatorPeriod = {
  month: IndicatorValue[];
  quarter: IndicatorValue[];
  total: IndicatorValue;
};

export type IndicatorValueWithIds = {
  term: string;
  value: number;
  ids: number[];
};

export type IndicatorPeriodWithIds = {
  month: IndicatorValueWithIds[];
  quarter: IndicatorValueWithIds[];
  total: IndicatorValueWithIds;
};

export type Indicator = {
  current: IndicatorPeriodWithIds;
  previous: IndicatorPeriodWithIds;
  yearlyGrowth: IndicatorPeriod;
};

export type DepartmentIndicator = {
  roleCode: string;
  itemId: number;
  indicator: Indicator;
  searchUrl: string | null;
};

export type DepartmentIndicators = DepartmentIndicator[];

const constructIndicatorsDepartmentValuesURL = (
  year: number,
  indicatorIds: string[]
): string => {
  const params = new URLSearchParams();
  params.append('year', `${year}`);
  indicatorIds.forEach(id => params.append('indicatorId', id));
  return `${insightsApiUrl}/api/indicators/departmentValues?${params.toString()}`;
};

export const fetchIndicatorsDepartmentValues = async (
  year: number,
  indicatorIds: string[]
): Promise<DepartmentIndicators> => {
  try {
    const url = constructIndicatorsDepartmentValuesURL(year, indicatorIds);
    const response = await getWebApi(url, {
      credentials: 'include',
    });
    const body = await response.json();
    return body as DepartmentIndicators;
  } catch (error) {
    console.error(
      `Fetch department indicator values for year ${year} failed:`,
      error
    );
    throw error;
  }
};
