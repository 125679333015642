import {useSelector, useDispatch} from 'react-redux';
import {useEffect} from 'react';
import {Outlet, Navigate} from 'react-router-dom';
import {RootState, AppDispatch} from '../app/store';
import {checkAuth} from '../app/slices/authSlice';

interface PrivateRouteProps {
  redirectTo?: string;
  children?: React.ReactNode;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  redirectTo = '/porters-login',
  children,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const {status, error} = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(checkAuth());
    }
  }, [status, dispatch]);

  if (error) {
    throw new Error(error.message || 'Unknown error');
  }

  if (status === 'checking') {
    return <div>Checking authentication...</div>;
  } else if (status === 'succeeded') {
    return <>{children || <Outlet />}</>;
  } else if (status === 'failed') {
    return <Navigate to={redirectTo} />;
  }

  // This should never happen, but it's good to be safe.
  return <div>Unknown status: {status}</div>;
};
