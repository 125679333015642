import React from 'react';
import {styled} from '@mui/material/styles';
import {
  Table as TableMUI,
  TableHead as TableHeadMUI,
  TableBody as TableBodyMUI,
  TableRow as TableRowMUI,
  TableCell as TableCellMUI,
} from '@mui/material';
import {STYLE_CONSTANTS} from './constants';

export type RenderContentFunction<T> = (
  data: T
) => JSX.Element | string | number;

// 共通のレンダリングロジックを関数で抽出
export const renderTableCellGeneric = <T,>(
  cellDataList: T[],
  renderContent: RenderContentFunction<T>,
  isTitle = false,
  cellComponents: any[]
): JSX.Element => {
  return (
    <>
      {cellDataList.map((cellData, colIndex) => {
        const isFirst = colIndex === 0;
        const isLast = colIndex === cellDataList.length - 1;

        const CellComponent = isFirst
          ? cellComponents[0]
          : isLast
          ? cellComponents[cellComponents.length - 1]
          : cellComponents[1];

        return (
          <React.Fragment key={colIndex}>
            <CellComponent className="cell-list chenge-color" isTitle={isTitle}>
              {renderContent(cellData)}
            </CellComponent>
          </React.Fragment>
        );
      })}
    </>
  );
};

export const renderTableCellBody = <T,>(
  cellDataList: T[],
  renderContent: RenderContentFunction<T>,
  isTitle = false
): JSX.Element => {
  return renderTableCellGeneric(cellDataList, renderContent, isTitle, [
    TableCellDataFirst,
    TableCellDataCenter,
    TableCellDataLast,
  ]);
};

export const renderTableCellHead = <T,>(
  cellDataList: T[],
  renderContent: RenderContentFunction<T>,
  isTitle = false
): JSX.Element => {
  return renderTableCellGeneric(cellDataList, renderContent, isTitle, [
    TableCellHeadFirst,
    TableCellHeadCenter,
    TableCellHeadLast,
  ]);
};

export const TableEnclosure = styled('div')({
  marginBottom: '20px',
  borderTop: STYLE_CONSTANTS.border,
  borderBottom: STYLE_CONSTANTS.border,
});

export const Table = styled(TableMUI)({
  tableLayout: 'fixed',
  width: 0,
});

export const TableDepartment = styled(Table)({
  border: 'none',
});

export const TableHead = styled(TableHeadMUI)({});

export const TableBody = styled(TableBodyMUI)({});

export const TableRow = styled(TableRowMUI)({});

export const TableRowHover = styled(TableRow)({
  '&:hover .cell-list': {
    backgroundColor: STYLE_CONSTANTS.bgColorDataHover,
  },
});

export const TableCell = styled(TableCellMUI)<{
  isTitle?: boolean;
  isHovered?: boolean;
}>(props => ({
  boxSizing: 'border-box',
  verticalAlign: 'top',
  padding: '5px 2px',
  textAlign: 'left',
  fontSize: '12px',
  height: STYLE_CONSTANTS.cellHeight,

  color: props.isTitle
    ? STYLE_CONSTANTS.fColorTitle
    : STYLE_CONSTANTS.fColorData,
  backgroundColor: props.isTitle
    ? STYLE_CONSTANTS.bgColorTitle
    : STYLE_CONSTANTS.bgColorData,
  border: STYLE_CONSTANTS.border,
}));

export const TableCellGap = styled(TableCell)({
  width: '18px',
  padding: 0,
  border: 'none',
  backgroundColor: 'transparent',
});

export const TableCellGhost = styled(TableCell)({
  padding: 0,
  border: 'none',
  backgroundColor: 'transparent',
});

export const TableCellTitle = styled(TableCell)({
  flex: `0 0 ${STYLE_CONSTANTS.titleWidth}`,
  width: STYLE_CONSTANTS.titleWidth,
}) as typeof TableCell;

export const TableCellTitleTop = styled(TableCellTitle)({
  borderTop: 'none',
}) as typeof TableCell;

export const TableCellTitleMiddle = styled(TableCellTitle)({
  borderTop: 'none',
  borderBottom: 'none',
}) as typeof TableCell;

export const TableCellTitleBottom = styled(TableCellTitle)({
  borderBottom: 'none',
}) as typeof TableCell;

export const TableCellIndicator = styled(TableCell)({
  flex: `0 0 ${STYLE_CONSTANTS.titleWidth}`,
  width: STYLE_CONSTANTS.indicatorWidth,
}) as typeof TableCell;

export const TableCellIndicatorTop = styled(TableCellIndicator)({
  borderBottom: 'none',
}) as typeof TableCell;

export const TableCellIndicatorMiddle = styled(TableCellIndicator)({
  borderTop: 'none',
  borderBottom: 'none',
}) as typeof TableCell;

export const TableCellIndicatorBottom = styled(TableCellIndicator)({
  borderTop: 'none',
}) as typeof TableCell;

export const TableCellIndicatorParent = styled(TableCell)({
  flex: `0 0 ${STYLE_CONSTANTS.titleWidth}`,
  width: STYLE_CONSTANTS.indicatorParentWidth,
}) as typeof TableCell;

export const TableCellIndicatorParentTop = styled(TableCellIndicatorParent)({
  borderBottom: 'none',
}) as typeof TableCell;

export const TableCellIndicatorParentMiddle = styled(TableCellIndicatorParent)({
  borderTop: 'none',
  borderBottom: 'none',
}) as typeof TableCell;

export const TableCellIndicatorParentBottom = styled(TableCellIndicatorParent)({
  borderTop: 'none',
}) as typeof TableCell;

export const TableCellIndicatorChild = styled(TableCell)({
  flex: `0 0 ${STYLE_CONSTANTS.titleWidth}`,
  width: STYLE_CONSTANTS.indicatorChildWidth,
}) as typeof TableCell;

export const TableCellIndicatorChildTop = styled(TableCellIndicatorChild)({
  flex: `0 0 ${STYLE_CONSTANTS.titleWidth}`,
  width: STYLE_CONSTANTS.indicatorChildWidth,
  borderBottom: 'none',
}) as typeof TableCell;

export const TableCellIndicatorChildMiddle = styled(TableCellIndicatorChild)({
  flex: `0 0 ${STYLE_CONSTANTS.titleWidth}`,
  width: STYLE_CONSTANTS.indicatorChildWidth,
  borderTop: 'none',
  borderBottom: 'none',
}) as typeof TableCell;

export const TableCellIndicatorChildBottom = styled(TableCellIndicatorChild)({
  flex: `0 0 ${STYLE_CONSTANTS.titleWidth}`,
  width: STYLE_CONSTANTS.indicatorChildWidth,
  borderTop: 'none',
}) as typeof TableCell;

export const TableCellDepartment = styled(TableCell)({
  flex: `0 0 ${STYLE_CONSTANTS.titleWidth}`,
  width: STYLE_CONSTANTS.departmentWidth,
  borderBottom: 'none',
}) as typeof TableCell;

export const TableCellDepartmentTop = styled(TableCellDepartment)({
  borderBottom: 'none',
}) as typeof TableCell;

export const TableCellDepartmentMiddle = styled(TableCellDepartment)({
  borderTop: 'none',
  borderBottom: 'none',
}) as typeof TableCell;

export const TableCellDepartmentBottom = styled(TableCellDepartment)({
  borderTop: 'none',
}) as typeof TableCell;

export const TableCellData = styled(TableCell)<{isHovered?: boolean}>(props =>
  props.isHovered
    ? {
        flex: `0 0 ${STYLE_CONSTANTS.dataWidth}`,
        width: STYLE_CONSTANTS.dataWidth,
        backgroundColor: '#e0e0e0',
      }
    : {
        flex: `0 0 ${STYLE_CONSTANTS.dataWidth}`,
        width: STYLE_CONSTANTS.dataWidth,
      }
) as typeof TableCell;

export const TableCellDataFirst = styled(TableCellData)({
  borderRight: 'none',
  borderBottom: 'none',
}) as typeof TableCellData;

export const TableCellDataCenter = styled(TableCellData)({
  borderLeft: 'none',
  borderRight: 'none',
  borderBottom: 'none',
}) as typeof TableCellData;

export const TableCellDataLast = styled(TableCellData)({
  borderLeft: 'none',
  borderBottom: 'none',
}) as typeof TableCellData;

export const TableCellHeadFirst = styled(TableCellData)({
  borderTop: 'none',
  borderRight: 'none',
  borderBottom: 'none',
}) as typeof TableCellData;

export const TableCellHeadCenter = styled(TableCellData)({
  borderTop: 'none',
  borderLeft: 'none',
  borderRight: 'none',
  borderBottom: 'none',
}) as typeof TableCellData;

export const TableCellHeadLast = styled(TableCellData)({
  borderTop: 'none',
  borderLeft: 'none',
  borderBottom: 'none',
}) as typeof TableCellData;
