import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface HoveredCellState {
  row: number | null;
  col: number | null;
}

const initialState: HoveredCellState = {
  row: null,
  col: null,
};

const hoveredCellSlice = createSlice({
  name: 'hoveredCell',
  initialState,
  reducers: {
    setHoveredCell: (state, action: PayloadAction<HoveredCellState>) => {
      state.row = action.payload.row;
      state.col = action.payload.col;
    },
    clearHoveredCell: state => {
      state.row = null;
      state.col = null;
    },
  },
});

export const {setHoveredCell, clearHoveredCell} = hoveredCellSlice.actions;

export default hoveredCellSlice.reducer;
