import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, AppDispatch} from '../app/store';
import {doWebApiLogin} from '../app/slices/webApiLoginSlice';
import {Loading} from './Loading';

export const WebApiLogin = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const {status, error} = useSelector((state: RootState) => state.webApiLogin);

  useEffect(() => {
    const code = new URLSearchParams(window.location.search).get('code');
    dispatch(doWebApiLogin(code));
  }, [dispatch]);

  useEffect(() => {
    if (status === 'succeeded') {
      navigate('/');
    }
  }, [status, navigate]);

  if (status === 'failed' && error) {
    throw new Error(error.message || 'Unknown error');
  }

  return <Loading />;
};
