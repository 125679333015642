import {createSlice, createAsyncThunk, SerializedError} from '@reduxjs/toolkit';
import {DepartmentData} from './departmentSlice';
import {fetchDepartmentsMembers} from '../../services/accesser/web-api-access.departments.members';

export interface MemberData {
  itemId: number;
  name: string;
}

export interface MemberState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: SerializedError | null;
  data: MemberData[];
}

const initialState: MemberState = {
  status: 'idle',
  error: null,
  data: [],
};

let selectedDepartmentName = '';

export const fetchMembers = createAsyncThunk(
  'department/fetchMembers',
  async (departmentData: DepartmentData) => {
    selectedDepartmentName = departmentData.name;
    const response = await fetchDepartmentsMembers(
      String(departmentData.itemId)
    );
    return response;
  }
);

export const memberSlice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    initializeMembers(state) {
      state.status = initialState.status;
      state.error = initialState.error;
      state.data = initialState.data;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchMembers.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchMembers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = [
          {
            itemId: 0,
            name: selectedDepartmentName,
          },
          ...action.payload
            .sort((a, b) => a.itemId - b.itemId)
            .map(item => ({
              itemId: item.itemId,
              name: item.name,
            })),
        ];
      })
      .addCase(fetchMembers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      });
  },
});

export const {initializeMembers} = memberSlice.actions;

export default memberSlice.reducer;
