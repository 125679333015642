import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit';
import {fetchDepartments as fetchDepartmentsApi} from '../../services/accesser/web-api-access.departments';

export interface DepartmentData {
  itemId: number;
  name: string;
}

export interface DepartmentState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: SerializedError | null;
  selectedItemId: number | undefined;
  data: DepartmentData[];
}

const initialState: DepartmentState = {
  status: 'idle',
  error: null,
  selectedItemId: undefined,
  data: [],
};

export const fetchDepartments = createAsyncThunk(
  'department/fetchDepartments',
  async () => {
    const response = await fetchDepartmentsApi();
    return response;
  }
);

export const departmentSlice = createSlice({
  name: 'department',
  initialState,
  reducers: {
    setSelectedItemId: (state, action: PayloadAction<number>) => {
      state.selectedItemId = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchDepartments.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchDepartments.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload
          .sort((a, b) => a.order - b.order)
          .map(item => ({
            itemId: item.itemId,
            name: item.name,
          }));
        state.data.unshift({
          itemId: 0,
          name: '全社',
        });
        // Set selectedItemId to the first element
        if (state.data.length > 0) {
          state.selectedItemId = state.data[0].itemId;
        }
      })
      .addCase(fetchDepartments.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      });
  },
});

export const {setSelectedItemId} = departmentSlice.actions;

export default departmentSlice.reducer;
