import Button from '@mui/material/Button';
import {useNavigate} from 'react-router-dom';

export const Logout = (): JSX.Element => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    navigate('/logout');
  };

  return (
    <Button variant="contained" color="primary" onClick={handleLogout}>
      ログアウト
    </Button>
  );
};
