import {createSlice, createAsyncThunk, SerializedError} from '@reduxjs/toolkit';
import {getConfig} from '../../config';
import {getCookie} from '../../utils/cookie';
import {parseJwt} from '../../utils/jwt';

interface UserInfo {
  partitionId: number;
  userId: number;
  name: string;
  timeZone: string;
}

export interface UserInfoState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  data: UserInfo | null;
  error: SerializedError | null;
}

const initialState: UserInfoState = {
  status: 'idle',
  data: null,
  error: null,
};

const {authTokenCookieName} = getConfig();

export const fetchUserInfo = createAsyncThunk(
  'userInfo/fetchUserInfo',
  async () => {
    const jwt = getCookie(authTokenCookieName);
    if (!jwt) {
      throw new Error(`No JWT found in cookie "${authTokenCookieName}".`);
    }
    const payload: {
      data: {
        partitionId: number;
        userId: number;
        name: string;
        timeZone: string;
      };
      [key: string]: unknown;
    } | null = parseJwt(jwt);
    if (!payload) {
      throw new Error(`Unable to parse JWT "${jwt}".`);
    }
    const {partitionId, userId, name, timeZone} = payload.data;
    return {partitionId, userId, name, timeZone};
  }
);

const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchUserInfo.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchUserInfo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchUserInfo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      });
  },
});

export default userInfoSlice.reducer;
