import React from 'react';
import {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {styled} from '@mui/system';
import {RootState, AppDispatch} from '../app/store';
import {
  IndicatorTitleDetail,
  fetchIndicatorTitles,
} from '../app/slices/indicatorTitleSlice';
import {
  fetchIndicatorDepartmentValues,
  fetchIndicatorDepartmentMemberValues,
} from '../app/slices/indicatorValueSlice';
import {
  setIndicatorViewData,
  startLoading,
} from '../app/slices/indicatorViewSlice';
import {Loading} from './Loading';
import {IndicatorTable} from './indicatorTable/IndicatorTable';

export const IndicatorViewTop = styled('div')({
  height: 750,
  overflowY: 'scroll',
  overflowX: 'scroll',
  flexGrow: 1,
});

export const IndicatorViewPanel = styled('div')({
  marginTop: 3,
  marginLeft: 10,
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  padding: '10px 0 10px 15px',
});

const IndicatorPanelItem = styled('div')({
  padding: '10px',
  backgroundColor: '#f2f2f2',
});

const IndicatorTitle = styled('div')({
  fontSize: '20px',
  fontWeight: 'bold',
  paddingTop: '5px',
  paddingBottom: '5px',
});

const extractRoleCodes = (data: IndicatorTitleDetail[]): string[] => {
  let roleCodes: string[] = [];
  for (const item of data) {
    roleCodes.push(item.roleCode);
    if (item.children && item.children.length > 0) {
      roleCodes = roleCodes.concat(extractRoleCodes(item.children));
    }
  }
  return roleCodes;
};

const allSucceeded = (...statuses: string[]) => {
  return statuses.every(status => status === 'succeeded');
};

export const IndicatorView = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const {selectedYear} = useSelector((state: RootState) => state.businessYear);
  const {
    selectedRoleCodes,
    data: indicatorGroupData,
    status: indicatorGroupStatus,
  } = useSelector((state: RootState) => state.indicatorGroup);
  const {data: indicatorTitleData, status: indicatorTitleStatus} = useSelector(
    (state: RootState) => state.indicatorTitle
  );
  const {data: indicatorValueData, status: indicatorValueStatus} = useSelector(
    (state: RootState) => state.indicatorValue
  );
  const {
    data: indicatorViewData,
    status: indicatorViewStatus,
    error: indicatorViewError,
  } = useSelector((state: RootState) => state.indicatorView);
  const {data: departmentData, selectedItemId} = useSelector(
    (state: RootState) => state.department
  );
  const {data: memberData} = useSelector((state: RootState) => state.member);

  const indicatorIds = useMemo(() => {
    const allIndicatorsTitles: IndicatorTitleDetail[] = Object.values(
      indicatorTitleData
    ).flatMap(item => item.indicatorTitles);

    if (allIndicatorsTitles.length) {
      return allIndicatorsTitles
        .map(indicatorTitle => extractRoleCodes(indicatorTitle.children))
        .flat()
        .filter(roleCodes => roleCodes !== 'INDICATOR0000');
    }
    return null;
  }, [indicatorTitleData]);

  useEffect(() => {
    if (indicatorIds && selectedYear) {
      if (selectedItemId) {
        dispatch(
          fetchIndicatorDepartmentMemberValues({
            departmentId: selectedItemId,
            year: selectedYear,
            indicatorIds,
          })
        );
      } else {
        dispatch(
          fetchIndicatorDepartmentValues({year: selectedYear, indicatorIds})
        );
      }
    }
  }, [dispatch, indicatorIds, selectedYear, selectedItemId]);

  useEffect(() => {
    if (
      allSucceeded(
        indicatorGroupStatus,
        indicatorTitleStatus,
        indicatorValueStatus
      )
    ) {
      dispatch(startLoading());
      if (selectedItemId) {
        dispatch(
          setIndicatorViewData({
            indicatorGroupData,
            indicatorTitleData,
            indicatorValueData,
            itemData: memberData,
          })
        );
      } else {
        dispatch(
          setIndicatorViewData({
            indicatorGroupData,
            indicatorTitleData,
            indicatorValueData,
            itemData: departmentData,
          })
        );
      }
    }
  }, [
    dispatch,
    indicatorGroupData,
    indicatorTitleData,
    indicatorValueData,
    selectedItemId,
  ]);

  useEffect(() => {
    if (indicatorGroupStatus === 'succeeded') {
      const groupIds = indicatorGroupData.map(item => item.roleCode);
      dispatch(fetchIndicatorTitles(groupIds));
    }
  }, [dispatch, indicatorGroupData, indicatorGroupStatus]);

  if (indicatorViewStatus === 'failed' && indicatorViewError) {
    throw new Error(indicatorViewError.message || 'Unknown error');
  }

  if (indicatorViewStatus === 'loading') {
    return <Loading />;
  }

  return (
    <IndicatorViewTop className="IndicatorViewTop">
      <IndicatorViewPanel className="IndicatorViewPanel">
        {indicatorViewData
          .filter(data => selectedRoleCodes.includes(data.roleCode))
          .map(data => {
            const panelName = data.name;
            const indicatorClusters = data.indicatorCluster;
            return (
              <React.Fragment key={data.roleCode}>
                <IndicatorPanelItem className="IndicatorItem">
                  <IndicatorTitle>{panelName}</IndicatorTitle>
                  {indicatorClusters.map((indicatorCluster, index) => (
                    <IndicatorTable
                      key={index}
                      indicatorParents={indicatorCluster.indicatorParents}
                    />
                  ))}
                </IndicatorPanelItem>
              </React.Fragment>
            );
          })}
      </IndicatorViewPanel>
    </IndicatorViewTop>
  );
};
