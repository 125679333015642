import {useSelector} from 'react-redux';
import {styled} from '@mui/system';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {RootState} from '../app/store';
import {SideMenu} from './SideMenu';
import {BusinessDayView} from './BusinessDayView';
import {IndicatorView} from './IndicatorView';
import {Footer} from './Footer';

export const InsightApp = (): JSX.Element => {
  const {status} = useSelector((state: RootState) => state.appLoad);

  return (
    <ThemeProvider theme={theme}>
      <AppContainer>
        <AppTopSection>
          <AppLeftSection>
            <SideMenu />
          </AppLeftSection>
          <AppRightSection>
            <BusinessDayView />
            <IndicatorView />
          </AppRightSection>
        </AppTopSection>
        <AppBottomSection>
          <Footer />
        </AppBottomSection>
        <Backdrop open={status === 'loading'}>
          <CircularProgress />
        </Backdrop>
      </AppContainer>
    </ThemeProvider>
  );
};

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        outlined: {
          fontSize: '16px',
          margin: 'auto',
          padding: '5.5px 0px',
          borderRadius: '5px',
          border: '2px solid #C4C4C4',
          backgroundColor: 'white',
          '&:hover': {
            borderColor: '#C4C4C4',
          },
        },
      },
    },
  },
});

const AppContainer = styled('div')({
  width: '100%',
});

const AppTopSection = styled('div')({
  display: 'flex',
  width: 'calc(100% - 5px)',
  boxSizing: 'border-box',
});

const AppBottomSection = styled('div')({});

const AppLeftSection = styled('div')({
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
});

const AppRightSection = styled('div')({
  // width: '1650px',
});
