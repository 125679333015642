import React from 'react';
import {
  IndicatorParent,
  IndicatorChild,
} from '../../app/slices/indicatorViewSlice';
import {TableEnclosure} from '../table/Table';
import {IndicatorTableHead} from './IndicatorTableHead';
import {IndicatorTableParent} from './IndicatorTableParent';
import {IndicatorTableChild} from './IndicatorTableChild';

const createRowIndicatorParent = (indicatorParent: IndicatorParent) => {
  return (
    <IndicatorTableParent
      searchUrl={indicatorParent.searchUrl}
      indicatorDatas={indicatorParent.data}
      name={indicatorParent.name}
    />
  );
};

const createRowIndicatorChild = (
  indicatorChildren: IndicatorChild[],
  nameParent?: string
) => {
  return (
    <>
      {indicatorChildren.map((indicatorChild, index) => {
        const isFirst = index === 0;
        return (
          <React.Fragment key={index}>
            {isFirst ? (
              <IndicatorTableChild
                searchUrl={indicatorChild.searchUrl}
                indicatorDatas={indicatorChild.data}
                name={indicatorChild.name}
                nameParent={nameParent}
              />
            ) : (
              <IndicatorTableChild
                searchUrl={indicatorChild.searchUrl}
                indicatorDatas={indicatorChild.data}
                name={indicatorChild.name}
              />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export interface IndicatorTableProps {
  indicatorParents: IndicatorParent[];
}

export const IndicatorTable = ({
  indicatorParents,
}: IndicatorTableProps): JSX.Element => {
  return (
    <TableEnclosure>
      <IndicatorTableHead />
      <>
        {indicatorParents.map(indicatorParent => {
          const hasData = indicatorParent.data.length > 0;
          return (
            <React.Fragment key={indicatorParent.roleCode}>
              {hasData && <>{createRowIndicatorParent(indicatorParent)}</>}
              {hasData
                ? createRowIndicatorChild(indicatorParent.indicatorChildren)
                : createRowIndicatorChild(
                    indicatorParent.indicatorChildren,
                    indicatorParent.name
                  )}
            </React.Fragment>
          );
        })}
      </>
    </TableEnclosure>
  );
};
