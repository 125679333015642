import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit';
import {fetchSchedulesBusinessYears} from '../../services/accesser/web-api-access.schedules.business-years';

export interface BusinessYearData {
  itemId: number;
  start: string;
  end: string;
}

export interface BusinessYearState {
  error: SerializedError | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  selectedItemId: number | null;
  selectedYear: number | null;
  startMonth: number | null;
  data: BusinessYearData[];
}

const initialState: BusinessYearState = {
  error: null,
  status: 'idle',
  selectedItemId: null,
  selectedYear: null,
  startMonth: null,
  data: [],
};

export const fetchBusinessYears = createAsyncThunk(
  'businessYear/fetchBusinessYears',
  async () => {
    const response = await fetchSchedulesBusinessYears();
    return response;
  }
);

// Helper function to extract year from start date
const parseYearAndMonth = (
  start: string | undefined
): {year: number; month: number} | null => {
  if (start) {
    const [year, month] = start.split('/');
    return {year: parseInt(year), month: parseInt(month)};
  }
  return null;
};

export const businessYearSlice = createSlice({
  name: 'businessYear',
  initialState,
  reducers: {
    setSelectedItemId: (state, action: PayloadAction<number>) => {
      state.selectedItemId = action.payload;
      const start = state.data.find(
        item => item.itemId === action.payload
      )?.start;
      const startData = parseYearAndMonth(start);
      state.selectedYear = startData?.year || null;
      state.startMonth = startData?.month || null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchBusinessYears.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchBusinessYears.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload.reverse().map((item, index) => ({
          itemId: index + 1,
          start: item.start,
          end: item.end,
        }));
        // Set selectedItemId and selectedYear to the first element
        if (state.data.length > 0) {
          state.selectedItemId = state.data[0].itemId;
          const startData = parseYearAndMonth(state.data[0].start);
          state.selectedYear = startData?.year || null;
          state.startMonth = startData?.month || null;
        }
      })
      .addCase(fetchBusinessYears.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      });
  },
});

export const {setSelectedItemId} = businessYearSlice.actions;

export default businessYearSlice.reducer;
