import {getWebApi} from './web-api-access';
import {getConfig} from '../../config';

const {insightsApiUrl} = getConfig();

const constructLoginURL = (code: string): string => {
  return `${insightsApiUrl}/api/login?code=${code}`;
};

export const doWebApiLogin = async (code: string | null): Promise<void> => {
  try {
    if (code === null) {
      throw new Error('No code provided.');
    }
    const url = constructLoginURL(code);
    await getWebApi(url, {credentials: 'include'});
  } catch (error) {
    console.error('Login failed:', error);
    throw error;
  }
};
