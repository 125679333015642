import {Buffer} from 'buffer';

export const parseJwt = <JWTPayload>(token: string): JWTPayload | null => {
  try {
    const parts = token.split('.');
    if (parts.length !== 3) {
      return null;
    }
    const decoded = base64Decode(parts[1]);
    const payload: JWTPayload = JSON.parse(decoded);
    return payload;
  } catch (error) {
    console.error('Failed to parse JWT', error);
    return null;
  }
};

const base64Decode = (base64: string) => {
  return Buffer.from(base64, 'base64').toString('utf-8');
};
