import React from 'react';
import {BusinessDayViewData} from '../../app/slices/businessDayViewSlice';
import {Table, TableBody, TableRow, TableCellTitleMiddle} from '../table/Table';
import {BusinessDayCellDay} from './BusinessDayCellDay';

export interface BusinessDayTableBodyProps {
  businessDayViewData: BusinessDayViewData;
}

export const BusinessDayTableBody: React.FC<BusinessDayTableBodyProps> = ({
  businessDayViewData,
}) => {
  if (!businessDayViewData) {
    return null;
  }

  const data = businessDayViewData;

  return (
    <>
      <Table>
        <TableBody>
          <TableRow>
            <TableCellTitleMiddle rowSpan={2}>{'営業日'}</TableCellTitleMiddle>
            <BusinessDayCellDay
              label={'前期'}
              month={data.month.previous}
              quarter={data.quarter.previous}
            />
          </TableRow>
          <TableRow>
            <BusinessDayCellDay
              label={'今期'}
              month={data.month.current}
              quarter={data.quarter.current}
            />
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};
