import {getWebApi} from './web-api-access';
import {getConfig} from '../../config';

const {insightsApiUrl} = getConfig();

export interface IndicatorsTitlesData {
  name: string;
  roleCode: string;
  order: number;
  children: IndicatorsTitlesData[];
}

export interface IndicatorsTitlesResponse {
  groupId: string;
  indicatorsTitles: IndicatorsTitlesData[];
}

const constructIndicatorsTitlesURL = (groupId: string[]): string => {
  const params = new URLSearchParams();
  groupId.forEach(id => params.append('groupId', id));
  return `${insightsApiUrl}/api/indicators/titles?${params.toString()}`;
};

export const fetchIndicatorsTitles = async (
  groupId: string[]
): Promise<IndicatorsTitlesResponse[]> => {
  try {
    const url = constructIndicatorsTitlesURL(groupId);
    const response = await getWebApi(url, {
      credentials: 'include',
    });
    const body = await response.json();
    return body as IndicatorsTitlesResponse[];
  } catch (error) {
    console.error('Fetch indicators titles data failed:', error);
    throw error;
  }
};
