import {createAsyncThunk, createSlice, SerializedError} from '@reduxjs/toolkit';
import {doWebApiLogin as doLogin} from '../../services/accesser/web-api-access.login';

export interface WebApiLoginState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: SerializedError | null;
}

const initialState: WebApiLoginState = {
  status: 'idle',
  error: null,
};

export const doWebApiLogin = createAsyncThunk(
  'webApiLogin/doWebApiLogin',
  async (code: string | null) => {
    await doLogin(code);
  }
);

const webApiLoginSlice = createSlice({
  name: 'webApiLogin',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(doWebApiLogin.pending, state => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(doWebApiLogin.fulfilled, state => {
        state.status = 'succeeded';
      })
      .addCase(doWebApiLogin.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      });
  },
});

export default webApiLoginSlice.reducer;
