import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit';
import {fetchIndicatorsGroups as fetchIndicatorsGroupsAPI} from '../../services/accesser/web-api-access.indicators.groups';

export interface IndicatorGroupData {
  roleCode: string;
  name: string;
}

export interface IndicatorGroupState {
  error: SerializedError | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  selectedRoleCodes: string[];
  data: IndicatorGroupData[];
}

const initialState: IndicatorGroupState = {
  error: null,
  status: 'idle',
  selectedRoleCodes: [],
  data: [],
};

export const fetchIndicatorsGroups = createAsyncThunk(
  'indicatorsGroup/fetchIndicatorsGroups',
  async () => {
    const response = await fetchIndicatorsGroupsAPI();
    return response;
  }
);

export const indicatorGroupSlice = createSlice({
  name: 'indicatorsGroup',
  initialState,
  reducers: {
    selectRoleCode: (state, action: PayloadAction<string>) => {
      if (state.selectedRoleCodes.includes(action.payload)) {
        state.selectedRoleCodes = state.selectedRoleCodes.filter(
          roleCode => roleCode !== action.payload
        );
      } else {
        state.selectedRoleCodes = [...state.selectedRoleCodes, action.payload];
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchIndicatorsGroups.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchIndicatorsGroups.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload.map(item => ({
          roleCode: item.roleCode,
          name: item.name,
        }));
        state.selectedRoleCodes = action.payload.map(item => item.roleCode);
      })
      .addCase(fetchIndicatorsGroups.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      });
  },
});

export const {selectRoleCode} = indicatorGroupSlice.actions;

export default indicatorGroupSlice.reducer;
