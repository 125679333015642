import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {PortersLogin} from './PortersLogin';
import {PortersLogout} from './PortersLogout';
import {WebApiLogin} from './WebApiLogin';
import {WebApiLogout} from './WebApiLogout';
import {NotFound} from './NotFound';
import {PrivateRoute} from './PrivateRoute';
import {InsightApp} from './InsightApp';
import {ErrorBoundary} from './ErrorBoundary';

/**
 * Render App
 */
export const App = (): JSX.Element => {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Routes>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <InsightApp />
              </PrivateRoute>
            }
          />
          <Route path="/porters-login" element={<PortersLogin />} />
          <Route path="/porters-logout" element={<PortersLogout />} />
          <Route path="/login" element={<WebApiLogin />} />
          <Route path="/logout" element={<WebApiLogout />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ErrorBoundary>
    </BrowserRouter>
  );
};
