import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {RootState, AppDispatch} from '../app/store';
import {fetchUserInfo} from '../app/slices/userInfoSlice';
import {styled} from '@mui/system';
import {Loading} from './Loading';

const UserInfoStyle = styled('div')({
  fontSize: '20px',
  fontWeight: 'bold',
  bottom: '0px',
  color: '#000000',
});

export const UserInfo = () => {
  const dispatch: AppDispatch = useDispatch();
  const {data, status, error} = useSelector(
    (state: RootState) => state.userInfo
  );

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchUserInfo());
    }
  }, [status, dispatch]);

  if (status === 'loading') {
    return <Loading />;
  }

  if (status === 'failed' && error) {
    throw new Error(error.message || 'Unknown error');
  }

  return <UserInfoStyle>{data?.name}</UserInfoStyle>;
};
