import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {styled} from '@mui/system';
import {RootState, AppDispatch} from '../app/store';
import {
  fetchDepartments,
  setSelectedItemId,
} from '../app/slices/departmentSlice';
import {fetchMembers, initializeMembers} from '../app/slices/memberSlice';
import {DepartmentItem} from './DepartmentItem';
import {Loading} from './Loading';

export const DepartmentItemWrapper = styled('div')({
  width: '100%',
  fontSize: '16px',
  cursor: 'pointer',
  maxHeight: '290px',
  overflowY: 'auto',
});

export const DepartmentSelector = (): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();
  const {data, selectedItemId, status, error} = useSelector(
    (state: RootState) => state.department
  );

  const onChangeHandler = (itemId: number): void => {
    dispatch(setSelectedItemId(itemId));
  };

  useEffect(() => {
    dispatch(fetchDepartments());
  }, [dispatch]);

  useEffect(() => {
    if (selectedItemId) {
      const selectedItem = data.find(
        department => department.itemId === selectedItemId
      );
      dispatch(fetchMembers(selectedItem!));
    } else {
      dispatch(initializeMembers());
    }
  }, [dispatch, selectedItemId]);

  if (status === 'failed' && error) {
    throw new Error(error.message || 'Unknown error');
  }

  if (status === 'loading') {
    return <Loading />;
  }

  return (
    <DepartmentItemWrapper className="department">
      {data.map(departmentData => (
        <DepartmentItem
          key={departmentData.itemId}
          itemName={departmentData.name}
          isSelected={selectedItemId === departmentData.itemId}
          onClick={() => onChangeHandler(departmentData.itemId)}
        />
      ))}
    </DepartmentItemWrapper>
  );
};
