import {createAsyncThunk, createSlice, SerializedError} from '@reduxjs/toolkit';
import {fetchIndicatorsTitles} from '../../services/accesser/web-api-access.indicators.titles';

export interface IndicatorTitleDetail {
  name: string;
  roleCode: string;
  order: number;
  children: IndicatorTitleDetail[];
}

export interface IndicatorTitleData {
  [groupId: string]: {
    indicatorTitles: IndicatorTitleDetail[];
  };
}

export interface IndicatorTitleState {
  error: SerializedError | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  data: IndicatorTitleData;
}

const initialState: IndicatorTitleState = {
  error: null,
  status: 'idle',
  data: {},
};

export const fetchIndicatorTitles = createAsyncThunk(
  'indicatorTitle/fetchIndicatorTitles',
  async (groupIds: string[]) => {
    const responseData = await fetchIndicatorsTitles(groupIds);
    return responseData.reduce<IndicatorTitleState['data']>(
      (accumulator, currentItem) => {
        accumulator[currentItem.groupId] = {
          indicatorTitles: currentItem.indicatorsTitles,
        };
        return accumulator;
      },
      {}
    );
  }
);

export const indicatorTitleSlice = createSlice({
  name: 'indicatorTitle',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchIndicatorTitles.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchIndicatorTitles.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchIndicatorTitles.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      });
  },
});

export default indicatorTitleSlice.reducer;
