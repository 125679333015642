import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Cookies from 'js-cookie';
import {getConfig} from '../config';
import {Loading} from './Loading';
import {RootState, AppDispatch} from '../app/store';
import {doWebApiLogout} from '../app/slices/webApiLogoutSlice';

const {authTokenCookieName} = getConfig();

export const WebApiLogout = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const {status, error} = useSelector((state: RootState) => state.webApiLogout);

  useEffect(() => {
    dispatch(doWebApiLogout());
  }, [dispatch]);

  useEffect(() => {
    if (status === 'succeeded') {
      Cookies.remove(authTokenCookieName);
      navigate('/porters-logout');
    }
  }, [status, error, navigate]);

  if (status === 'failed' && error) {
    throw new Error(error.message || 'Unknown error');
  }

  return <Loading />;
};
