export interface ErrorMessage {
  message: string;
}

const fetchWithErrorHandling = async (
  input: RequestInfo,
  init?: RequestInit
): Promise<Response> => {
  const response = await fetch(input, init);
  if (!response.ok) {
    throw new Error(
      `Got status ${response.status} for ${
        init?.method || 'GET'
      } request to ${input}: ${await response.text()}`
    );
  }
  return response;
};

const commonHeaders = {
  'Content-Type': 'application/json',
};

export const getWebApi = async (
  input: RequestInfo,
  init?: RequestInit
): Promise<Response> => {
  return fetchWithErrorHandling(input, {
    ...init,
    method: 'GET',
    headers: {
      ...commonHeaders,
      ...init?.headers,
    },
  });
};

export const postWebApi = async <BODY>(
  input: RequestInfo,
  body: BODY,
  init?: RequestInit
): Promise<Response> => {
  return fetchWithErrorHandling(input, {
    ...init,
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      ...commonHeaders,
      ...init?.headers,
    },
  });
};
