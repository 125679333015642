import React from 'react';
import {IndicatorValue} from '../../app/slices/indicatorValueSlice';
import {renderTableCellBody, TableCellGap} from '../table/Table';

export interface IndicatorCellDataWithUnitProps {
  label: string;
  month: IndicatorValue[];
  quarter: IndicatorValue[];
  total: IndicatorValue;
}

export const IndicatorCellDataWithUnit: React.FC<
  IndicatorCellDataWithUnitProps
> = ({label, month, quarter, total}) => {
  const monthList = [label, ...month, total];

  return (
    <>
      {renderTableCellBody(
        monthList,
        (data: string | IndicatorValue) => {
          if (typeof data === 'string') {
            return data;
          }
          return `${data.value}%`;
        },
        false
      )}
      <TableCellGap />
      {renderTableCellBody(
        quarter,
        (data: IndicatorValue) => `${data.value}%`,
        false
      )}
    </>
  );
};
