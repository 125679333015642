import {createSlice, PayloadAction, SerializedError} from '@reduxjs/toolkit';

export interface IndicatorHeaderValue {
  title: string[];
}

export interface IndicatorHeaderData {
  month: IndicatorHeaderValue;
  quarter: IndicatorHeaderValue;
}

export interface IndicatorHeaderState {
  error: SerializedError | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  data?: IndicatorHeaderData;
}

const initialState: IndicatorHeaderState = {
  error: null,
  status: 'idle',
  data: undefined,
};

const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const indicatorHeaderSlice = createSlice({
  name: 'indicatorHeader',
  initialState,
  reducers: {
    startLoading(state) {
      state.status = 'loading';
    },
    hasError(state, action: PayloadAction<SerializedError>) {
      state.status = 'failed';
      state.error = action.payload;
    },
    setIndicatorHeaderData(state, action: PayloadAction<number>) {
      const startMonth = action.payload;

      // 開始月を基準に月のリストを並び替え
      const sortedMonths = [
        ...months.slice(startMonth - 1),
        ...months.slice(0, startMonth - 1),
      ];

      const month = {
        title: [...sortedMonths.map(month => month + '月'), '年間'],
      };
      const quarter = {
        title: sortedMonths
          .filter((_, index) => index % 3 === 0)
          .map(
            (month, index) => month + ' - ' + sortedMonths[index * 3 + 2] + '月'
          ),
      };

      state.data = {
        month,
        quarter,
      };

      state.status = 'succeeded';
    },
  },
});

export const {startLoading, hasError, setIndicatorHeaderData} =
  indicatorHeaderSlice.actions;

export default indicatorHeaderSlice.reducer;
