import {styled} from '@mui/system';

export const DepartmentItemName = styled('div')<{isSelected: boolean}>(
  ({isSelected}) => ({
    marginTop: '5px',
    color: isSelected ? '#FFFFFF' : 'inherit',
    backgroundColor: isSelected ? '#005faa' : 'inherit',
    '&:hover': {
      backgroundColor: '#005faa',
      color: '#FFFFFF',
    },
  })
);

interface DepartmentItemProps {
  itemName: string;
  isSelected: boolean;
  onClick: () => void;
}

export const DepartmentItem: React.FC<DepartmentItemProps> = ({
  itemName,
  isSelected,
  onClick,
}) => {
  return (
    <DepartmentItemName
      className="department-item"
      isSelected={isSelected}
      onClick={onClick}
    >
      {itemName}
    </DepartmentItemName>
  );
};
