import {createSlice, PayloadAction, SerializedError} from '@reduxjs/toolkit';
import {BusinessDayData, BusinessDay} from './businessDaySlice';

export interface BusinessDayViewData {
  month: {
    current: BusinessDay[];
    previous: BusinessDay[];
  };
  quarter: {
    current: BusinessDay[];
    previous: BusinessDay[];
  };
}

export interface BusinessDayViewState {
  error: SerializedError | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  data: BusinessDayViewData;
}

const initialState: BusinessDayViewState = {
  error: null,
  status: 'idle',
  data: {
    month: {
      current: [],
      previous: [],
    },
    quarter: {
      current: [],
      previous: [],
    },
  },
};

const calculateTotal = (
  businessDays: BusinessDay[],
  term: string
): BusinessDay => {
  const totalConsumptionDays = businessDays.reduce(
    (sum, element) => sum + element.consumptionDays,
    0
  );
  const totalWorkingDays = businessDays.reduce(
    (sum, element) => sum + element.workingDays,
    0
  );

  return {
    term: term,
    consumptionDays: totalConsumptionDays,
    workingDays: totalWorkingDays,
  };
};

const calculateYearlyTotal = (businessDays: BusinessDay[]): BusinessDay => {
  const term =
    businessDays[0].term + '-' + businessDays[businessDays.length - 1].term;
  return calculateTotal(businessDays, term);
};

const calculateQuarterTotal = (businessDays: BusinessDay[]): BusinessDay[] => {
  let quarterData: BusinessDay[] = [];
  const quarterTotals: BusinessDay[] = [];
  let count = 0;

  for (const businessDay of businessDays) {
    count++;
    quarterData.push(businessDay);

    if (count === 3) {
      const term = quarterData[0].term + '-' + quarterData[2].term;
      quarterTotals.push(calculateTotal(quarterData, term));

      count = 0;
      quarterData = [];
    }
  }

  return quarterTotals;
};

const businessDaysViewSlice = createSlice({
  name: 'businessDaysView',
  initialState,
  reducers: {
    startLoading(state) {
      state.status = 'loading';
    },
    hasError(state, action: PayloadAction<SerializedError>) {
      state.status = 'failed';
      state.error = action.payload;
    },
    setBusinessDayViewData(state, action: PayloadAction<BusinessDayData>) {
      const businessDaysData = action.payload;

      const month = {
        current: [
          ...businessDaysData.current,
          calculateYearlyTotal(businessDaysData.current),
        ],
        previous: [
          ...businessDaysData.previous,
          calculateYearlyTotal(businessDaysData.previous),
        ],
      };
      const quarter = {
        current: calculateQuarterTotal(businessDaysData.current),
        previous: calculateQuarterTotal(businessDaysData.previous),
      };

      state.data = {
        month,
        quarter,
      };

      state.status = 'succeeded';
    },
  },
});

export const {startLoading, hasError, setBusinessDayViewData} =
  businessDaysViewSlice.actions;

export default businessDaysViewSlice.reducer;
