const config = {
  insightsUrl: process.env.REACT_APP_INSIGHTS_URL ?? 'http://localhost:2000',
  insightsApiUrl:
    process.env.REACT_APP_INSIGHTS_API_URL ?? 'http://localhost:3000',
  portersUrl:
    process.env.REACT_APP_PORTERS_URL ?? 'https://hrbc-jp.porterscloud.com',
  portersAppId: process.env.REACT_APP_PORTERS_APP_ID ?? '',
  portersApiUrl:
    process.env.REACT_APP_PORTERS_API_URL ??
    'https://api-hrbc-jp.porterscloud.com',
  authTokenCookieName:
    process.env.REACT_APP_AUTH_TOKEN_COOKIE_NAME || 'authToken',
};

export const getConfig = () => config;
