import {createSlice, PayloadAction, SerializedError} from '@reduxjs/toolkit';
import {IndicatorGroupData} from './indicatorGroupSlice';
import {IndicatorTitleData, IndicatorTitleDetail} from './indicatorTitleSlice';
import {
  IndicatorValueData,
  Indicator,
  IndicatorValue,
  IndicatorValueWithIds,
} from './indicatorValueSlice';
import {DepartmentData} from './departmentSlice';

export interface IndicatorData extends Indicator {
  name: string;
  itemId: number; // departmentId or memberId
}

export interface IndicatorChild {
  name: string;
  roleCode: string;
  searchUrl: string | null;
  data: IndicatorData[];
}

export interface IndicatorParent {
  name: string;
  roleCode: string;
  searchUrl: string | null;
  data: IndicatorData[];
  indicatorChildren: IndicatorChild[];
}

export interface IndicatorCluster {
  indicatorParents: IndicatorParent[];
}

export interface IndicatorViewData {
  name: string;
  roleCode: string;
  indicatorCluster: IndicatorCluster[];
}

export interface IndicatorViewState {
  error: SerializedError | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  data: IndicatorViewData[];
}

const initialState: IndicatorViewState = {
  error: null,
  status: 'idle',
  data: [],
};

// デフォルトのmonthとquarterの配列を定義
const defaultValue: IndicatorValue = {term: '', value: 0};
const defaultMonthDetail: IndicatorValue[] = Array(12).fill(defaultValue);
const defaultQuarterDetail: IndicatorValue[] = Array(4).fill(defaultValue);

const defaultValueWithIds: IndicatorValueWithIds = {
  term: '',
  value: 0,
  ids: [],
};
const defaultMonthDetailWithIds: IndicatorValueWithIds[] =
  Array(12).fill(defaultValueWithIds);
const defaultQuarterDetailWithIds: IndicatorValueWithIds[] =
  Array(4).fill(defaultValueWithIds);

const createData = (
  roleCode: string,
  item: DepartmentData,
  indicatorValueData: IndicatorValueData
): IndicatorData => {
  const {name, itemId} = item;

  const indicator: Indicator =
    indicatorValueData[roleCode] && indicatorValueData[roleCode].items[itemId]
      ? indicatorValueData[roleCode].items[itemId]
      : {
          current: {
            month: defaultMonthDetailWithIds,
            quarter: defaultQuarterDetailWithIds,
            total: defaultValueWithIds,
          },
          previous: {
            month: defaultMonthDetailWithIds,
            quarter: defaultQuarterDetailWithIds,
            total: defaultValueWithIds,
          },
          yearlyGrowth: {
            month: defaultMonthDetail,
            quarter: defaultQuarterDetail,
            total: defaultValue,
          },
        };

  return {
    name,
    itemId,
    ...indicator,
  };
};

const createIndicatorChild = (
  indicatorTitleDetail: IndicatorTitleDetail,
  indicatorValueData: IndicatorValueData,
  itemData: DepartmentData[]
): IndicatorChild => {
  const {name, roleCode} = indicatorTitleDetail;

  const searchUrl: string | null = indicatorValueData[roleCode]
    ? indicatorValueData[roleCode].searchUrl
    : null;

  return {
    name,
    roleCode,
    searchUrl,
    data:
      roleCode !== 'INDICATOR0000'
        ? itemData.map(item => createData(roleCode, item, indicatorValueData))
        : [],
  };
};

const createIndicatorParent = (
  indicatorTitleDetail: IndicatorTitleDetail,
  indicatorValueData: IndicatorValueData,
  itemData: DepartmentData[]
): IndicatorParent => {
  const {name, roleCode} = indicatorTitleDetail;

  const searchUrl: string | null = indicatorValueData[roleCode]
    ? indicatorValueData[roleCode].searchUrl
    : null;

  return {
    name,
    roleCode,
    searchUrl,
    data:
      roleCode !== 'INDICATOR0000'
        ? itemData.map(item => createData(roleCode, item, indicatorValueData))
        : [],
    indicatorChildren: indicatorTitleDetail.children.map(IndicatorTitleDetail =>
      createIndicatorChild(IndicatorTitleDetail, indicatorValueData, itemData)
    ),
  };
};

const createIndicatorCluster = (
  indicatorTitles: IndicatorTitleDetail,
  indicatorValueData: IndicatorValueData,
  itemData: DepartmentData[]
): IndicatorCluster => {
  return {
    indicatorParents: indicatorTitles.children.map(indicatorTitleDetail =>
      createIndicatorParent(indicatorTitleDetail, indicatorValueData, itemData)
    ),
  };
};

const indicatorViewSlice = createSlice({
  name: 'indicatorView',
  initialState,
  reducers: {
    startLoading(state) {
      state.status = 'loading';
    },
    hasError(state, action: PayloadAction<SerializedError>) {
      state.status = 'failed';
      state.error = action.payload;
    },
    setIndicatorViewData(
      state,
      action: PayloadAction<{
        indicatorGroupData: IndicatorGroupData[];
        indicatorTitleData: IndicatorTitleData;
        indicatorValueData: IndicatorValueData;
        itemData: DepartmentData[];
      }>
    ) {
      const {
        indicatorGroupData,
        indicatorTitleData,
        indicatorValueData,
        itemData,
      } = action.payload;

      state.data = indicatorGroupData.map(groupData => ({
        ...groupData,
        indicatorCluster: indicatorTitleData[
          groupData.roleCode
        ].indicatorTitles.map(indicatorTitles =>
          createIndicatorCluster(indicatorTitles, indicatorValueData, itemData)
        ),
      }));
      state.status = 'succeeded';
    },
  },
});

export const {startLoading, hasError, setIndicatorViewData} =
  indicatorViewSlice.actions;

export default indicatorViewSlice.reducer;
