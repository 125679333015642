import {useEffect, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {RootState, AppDispatch} from '../app/store';
import {
  fetchBusinessYears,
  setSelectedItemId,
} from '../app/slices/businessYearSlice';
import {setIndicatorHeaderData} from '../app/slices/indicatorHeaderSlice';
import {Select, MenuItem, SelectChangeEvent} from '@mui/material';
import {formatString} from '../utils/date/date-helpers';

const selectStyle = {
  boxSizing: 'border-box',
  width: '174px',
  marginRight: '10px',
  '& .MuiInputBase-root': {
    width: '100%',
  },
  '& .MuiOutlinedInput-input': {
    padding: '2px 2px 2px 5px',
    backgroundColor: 'white',
    border: '2px solid #C4C4C4',
    borderRadius: '7px',
    '&:hover': {
      borderColor: '#C4C4C4',
    },
  },
  '&.MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#C4C4C4',
      borderRadius: '7px',
    },
    '&:hover fieldset': {
      borderColor: '#C4C4C4',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#C4C4C4',
    },
  },
};

export const BusinessYearSelector = (): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();
  const {data, selectedItemId, startMonth} = useSelector(
    (state: RootState) => state.businessYear
  );

  const onChangeHanlder = useCallback(
    (event: SelectChangeEvent<number>): void => {
      dispatch(setSelectedItemId(Number(event.target.value)));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(fetchBusinessYears());
  }, [dispatch]);

  useEffect(() => {
    if (startMonth) {
      dispatch(setIndicatorHeaderData(startMonth));
    }
  }, [dispatch, startMonth]);

  return (
    <Select
      sx={selectStyle}
      onChange={onChangeHanlder}
      value={selectedItemId || ''}
    >
      {data.map(businessYearData => {
        return (
          <MenuItem
            key={businessYearData.itemId}
            value={businessYearData.itemId}
          >
            {formatString(new Date(businessYearData.start), '%y/%m')}
            {' ～ '}
            {formatString(new Date(businessYearData.end), '%y/%m')}
          </MenuItem>
        );
      })}
    </Select>
  );
};
