import {FC} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../app/store';
import {Button} from '@mui/material';
import {styled} from '@mui/system';
import {generateCsvData} from '../services/generateCsvData';
import downloadImage from '../image/download.png';

const DownloadIcon = styled('span')({
  display: 'inline-block',
  height: '15px',
  width: '18px',
  backgroundSize: 'contain',
  backgroundImage: `url(${downloadImage})`,
  cursor: 'pointer',
});

const downloadCsv = (blob: Blob, filename: string): void => {
  const url = (window.URL || window.webkitURL).createObjectURL(blob);
  const download = document.createElement('a');
  download.href = url;
  download.download = filename;
  download.click();
  (window.URL || window.webkitURL).revokeObjectURL(url);
};

export const DownloadButtonCSV: FC = () => {
  const {indicatorHeaderData, indicatorViewData} = useSelector(
    (state: RootState) => ({
      indicatorHeaderData: state.indicatorHeader.data,
      indicatorViewData: state.indicatorView.data,
    })
  );

  const csvOutput = (): void => {
    const filename = 'download.csv';
    const blob = generateCsvData(indicatorHeaderData, indicatorViewData);
    downloadCsv(blob, filename);
  };

  return (
    <Button variant="outlined" onClick={csvOutput} sx={{minWidth: '40px'}}>
      <DownloadIcon />
    </Button>
  );
};
