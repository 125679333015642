import React from 'react';
import {styled} from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

const ArrowDropDownCircleIconOpen = styled(ArrowDropDownCircleIcon)({
  transform: 'rotate(0deg)',
  cursor: 'pointer',
  fontSize: '1.25rem',
});

const ArrowDropDownCircleIconClose = styled(ArrowDropDownCircleIcon)({
  transform: 'rotate(270deg)',
  cursor: 'pointer',
  fontSize: '1.25rem',
});

export interface DropDownIconProps {
  open: boolean;
  onClick: () => void;
}

export const DropDownIcon: React.FC<DropDownIconProps> = ({open, onClick}) => {
  return (
    <IconButton size="small" onClick={onClick}>
      {open ? (
        <ArrowDropDownCircleIconOpen />
      ) : (
        <ArrowDropDownCircleIconClose />
      )}
    </IconButton>
  );
};
