import React from 'react';
import {styled} from '@mui/system';
import {BusinessDay} from '../../app/slices/businessDaySlice';
import {renderTableCellBody, TableCellGap} from '../table/Table';

const ConsumptionDays = styled('strong')({
  color: '#de027a',
  fontWeight: 'bold',
});

const renderBusinessDayCell = (cellData: BusinessDay) => {
  if (cellData.consumptionDays === 0) {
    return <span style={{color: '#f0e9e6'}}>{cellData.workingDays}</span>;
  }

  if (cellData.workingDays <= cellData.consumptionDays) {
    return cellData.workingDays;
  }

  return (
    <>
      <ConsumptionDays>{cellData.consumptionDays}</ConsumptionDays>
      {'/'}
      {cellData.workingDays}
    </>
  );
};

export interface BusinessDayCellDayProps {
  label: string;
  month: BusinessDay[];
  quarter: BusinessDay[];
}

export const BusinessDayCellDay: React.FC<BusinessDayCellDayProps> = ({
  label,
  month,
  quarter,
}) => {
  const monthList = [label, ...month];

  return (
    <>
      {renderTableCellBody(monthList, (data: string | BusinessDay) => {
        if (typeof data === 'string') {
          return data;
        }
        return renderBusinessDayCell(data);
      })}
      <TableCellGap />
      {renderTableCellBody(quarter, (data: string | BusinessDay) => {
        if (typeof data === 'string') {
          return data;
        }
        return renderBusinessDayCell(data);
      })}
    </>
  );
};
