import {getWebApi} from './web-api-access';
import {getConfig} from '../../config';

const {insightsApiUrl} = getConfig();

const constructLogoutURL = (): string => {
  return `${insightsApiUrl}/api/logout`;
};

export const doWebApiLogout = async (): Promise<void> => {
  try {
    const url = constructLogoutURL();
    await getWebApi(url, {credentials: 'include'});
  } catch (error) {
    console.error('Logout failed:', error);
    throw error;
  }
};
