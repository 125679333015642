import {useEffect} from 'react';
import {getConfig} from '../config';
import {Loading} from './Loading';

const {portersUrl} = getConfig();

export const PortersLogout = () => {
  useEffect(() => {
    const logoutURL = `${portersUrl}/index/logout`;
    window.location.href = logoutURL;
  }, []);

  return <Loading />;
};
