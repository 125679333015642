import {getWebApi} from './web-api-access';
import {getConfig} from '../../config';
import {Indicator} from './web-api-access.indicators.department-values';

const {insightsApiUrl} = getConfig();

export interface DepartmentMemberIndicator {
  roleCode: string;
  itemId: number;
  indicator: Indicator;
  searchUrl: string | null;
}

export type DepartmentMemberIndicators = DepartmentMemberIndicator[];

const constructIndicatorsDepartmentsMemberValuesURL = (
  id: number,
  year: number,
  indicatorIds: string[]
): string => {
  const params = new URLSearchParams();
  params.append('year', `${year}`);
  indicatorIds.forEach(id => params.append('indicatorId', id));
  return `${insightsApiUrl}/api/indicators/departments/${id}/memberValues?${params.toString()}`;
};

export const fetchIndicatorsDepartmentsMemberValues = async (
  id: number,
  year: number,
  indicatorIds: string[]
): Promise<DepartmentMemberIndicators> => {
  try {
    const url = constructIndicatorsDepartmentsMemberValuesURL(
      id,
      year,
      indicatorIds
    );
    const response = await getWebApi(url, {
      credentials: 'include',
    });
    const body = await response.json();
    return body as DepartmentMemberIndicators;
  } catch (error) {
    console.error(
      `Fetch department member indicator values for department ID ${id} and year ${year} failed:`,
      error
    );
    throw error;
  }
};
