import {getWebApi} from './web-api-access';
import {getConfig} from '../../config';

const {insightsApiUrl} = getConfig();

export interface BusinessYearsResponse {
  start: string;
  end: string;
}

const constructSchedulesBusinessYearsURL = (): string => {
  return `${insightsApiUrl}/api/schedules/businessYears`;
};

export const fetchSchedulesBusinessYears = async (): Promise<
  BusinessYearsResponse[]
> => {
  try {
    const url = constructSchedulesBusinessYearsURL();
    const response = await getWebApi(url, {credentials: 'include'});
    const body = await response.json();
    return body as BusinessYearsResponse[];
  } catch (error) {
    console.error('Fetch business years data failed:', error);
    throw error;
  }
};
