import {useEffect, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {RootState, AppDispatch} from '../app/store';
import {
  fetchIndicatorsGroups,
  selectRoleCode,
} from '../app/slices/indicatorGroupSlice';
import {Checkbox, FormControlLabel, FormGroup} from '@mui/material';
import {styled} from '@mui/system';
import {Loading} from './Loading';

export const StyledFormGroup = styled(FormGroup)({
  '& .MuiCheckbox-root': {
    padding: '2px',
  },
});

export const IndicatorGroupSelector = (): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();
  const {data, selectedRoleCodes, status, error} = useSelector(
    (state: RootState) => state.indicatorGroup
  );

  const handleCheckboxChange = useCallback(
    (roleCode: string) => {
      dispatch(selectRoleCode(roleCode));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(fetchIndicatorsGroups());
  }, [dispatch]);

  if (status === 'failed' && error) {
    throw new Error(error.message || 'Unknown error');
  }

  if (status === 'loading') {
    return <Loading />;
  }

  return (
    <StyledFormGroup>
      {data.map(indicatorsGroupsData => (
        <FormControlLabel
          className="indicator-group-item"
          key={indicatorsGroupsData.roleCode}
          control={
            <Checkbox
              id={indicatorsGroupsData.roleCode}
              onChange={() =>
                handleCheckboxChange(indicatorsGroupsData.roleCode)
              }
              checked={selectedRoleCodes.includes(
                indicatorsGroupsData.roleCode
              )}
            />
          }
          label={indicatorsGroupsData.name}
        />
      ))}
    </StyledFormGroup>
  );
};
