import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../../app/store';
import {Loading} from '../Loading';
import {
  Table,
  TableHead,
  TableRow,
  TableCellIndicatorMiddle,
  TableCellDepartmentMiddle,
  renderTableCellHead,
  TableCellGap,
} from '../table/Table';

export const IndicatorTableHead = (): JSX.Element => {
  const {status, error, data} = useSelector(
    (state: RootState) => state.indicatorHeader
  );

  if (status === 'failed' && error) {
    throw new Error(error.message || 'Unknown error');
  }

  if (status === 'loading') {
    return <Loading />;
  }

  return (
    <Table className="indicatorTableHead">
      <TableHead>
        <TableRow>
          <TableCellIndicatorMiddle isTitle>{'指標'}</TableCellIndicatorMiddle>
          <TableCellDepartmentMiddle isTitle>
            {'部署'}
          </TableCellDepartmentMiddle>
          {renderTableCellHead(
            ['前/今期', ...(data?.month.title || [])],
            (data: string) => data,
            true
          )}
          <TableCellGap />
          {renderTableCellHead(
            data?.quarter.title || [],
            (data: string) => data,
            true
          )}
        </TableRow>
      </TableHead>
    </Table>
  );
};
