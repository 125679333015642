import React, {useState} from 'react';

const useToast = (duration = 2000) => {
  const [isVisible, setIsVisible] = useState(false);

  const showToast = () => {
    setIsVisible(true);
    setTimeout(() => setIsVisible(false), duration);
  };

  return {isVisible, showToast};
};

interface IndicatorLinkProps {
  value: number;
  searchUrl?: string | null;
  ids?: number[];
}

const IndicatorLink: React.FC<IndicatorLinkProps> = ({
  value,
  searchUrl,
  ids,
}) => {
  const {isVisible, showToast} = useToast();

  // トーストメッセージ用のスタイル
  const toastStyle: React.CSSProperties = {
    position: 'fixed',
    top: '50px',
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: 'black',
    color: 'white',
    padding: '10px',
    borderRadius: '5px',
    zIndex: 1000,
    display: isVisible ? 'block' : 'none',
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        showToast();
      })
      .catch(err => {
        console.error('クリップボードへのコピーに失敗しました', err);
      });
  };

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (!e.ctrlKey && !e.metaKey) {
      e.preventDefault();
      window.open(`${searchUrl}/${ids?.join(',')}`, '_blank');
    } else {
      copyToClipboard(`${ids?.join('\n')}`);
    }
  };

  return (
    <>
      <div style={toastStyle}>クリップボードにコピーしました！</div>
      <a
        style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}}
        role="link"
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleClick}
      >
        {value}
      </a>
    </>
  );
};

export default IndicatorLink;
