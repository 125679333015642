import {Box, Typography, Link} from '@mui/material';

export const Footer = (): JSX.Element => {
  return (
    <Box sx={{backgroundColor: 'white', padding: '1rem'}}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <Box
          component="ul"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            listStyle: 'none',
            padding: 0,
            gap: '1rem',
          }}
        >
          <li>
            <Link
              href="https://hrbc-jp.porterscloud.com/common/license"
              target="_blank"
              rel="noopener noreferrer"
            >
              利用規約
            </Link>
          </li>
          <li>
            <Link component="a" href="#">
              サービスレベルアグリーメント
            </Link>
          </li>
          <li>
            <Link component="a" href="#">
              PORTERSについて
            </Link>
          </li>
        </Box>
        <Typography variant="body2" color="text.secondary">
          copyright © PORTERS Corporation All Rights Reserved.
        </Typography>
      </Box>
    </Box>
  );
};
