import {getWebApi} from './web-api-access';
import {getConfig} from '../../config';

const {insightsApiUrl} = getConfig();

export interface DepartmentsResponse {
  itemId: number;
  name: string;
  order: number;
}

const constructDepartmentsURL = (): string => {
  return `${insightsApiUrl}/api/departments`;
};

export const fetchDepartments = async (): Promise<DepartmentsResponse[]> => {
  try {
    const url = constructDepartmentsURL();
    const response = await getWebApi(url, {
      credentials: 'include',
    });
    const body = await response.json();
    return body as DepartmentsResponse[];
  } catch (error) {
    console.error('Fetch departments data failed:', error);
    throw error;
  }
};
