import {Box, Typography} from '@mui/material';
import {styled} from '@mui/system';
import homeImage from '../image/home.png';
import {BusinessYearSelector} from './BusinessYearSelector';
import {DownloadButtonCSV} from './DownloadButtonCSV';
import {DepartmentSelector} from './DepartmentSelector';
import {IndicatorGroupSelector} from './IndicatorGroupSelector';
import {UserInfo} from './UserInfo';
import {Logout} from './Logout';

const SideMenuContainer = styled(Box)({
  width: 'calc(100% - 5px)',
  boxSizing: 'border-box',
  background: '#f2f2f2',
  position: 'relative',
  height: '880px',
  padding: '5px 10px',
  boxShadow: '0px 5px rgb(0 0 0 / 5%) inset',
});

const SideMenuHeader = styled(Box)({
  marginBottom: '5px',
  cursor: 'pointer',
});

const SideMenuBody = styled(Box)({
  background: '#f2f2f2',
  position: 'relative',
  marginBottom: '5px',
});

const SideMenuFooter = styled(Box)({
  position: 'absolute',
  bottom: '0px',
  width: '100%',
  marginBottom: '10px',
  cursor: 'pointer',
});

const SideMenuItemGroup = styled(Box)({
  marginBottom: '10px',
});

const SideMenuItemGroupTitle = styled(Typography)({
  fontSize: '20px',
  fontWeight: 'bold',
});

export const SideMenu = (): JSX.Element => {
  const openPortersTop = () => {
    const logo = document.createElement('a');
    logo.href = 'https://hrbc-jp.porterscloud.com/common/navigation';
    logo.target = '_blank';
    logo.click();
  };

  return (
    <SideMenuContainer>
      <SideMenuHeader onClick={openPortersTop}>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          padding="5px 0px"
        >
          <Box
            sx={{
              display: 'inline-block',
              width: '32px',
              height: '32px',
              margin: '0px 5px',
              backgroundSize: 'contain',
              backgroundImage: `url(${homeImage})`,
            }}
          />
          <Typography
            display="inline-block"
            color="#005faa"
            fontSize="28px"
            fontWeight="bold"
          >
            Insights
          </Typography>
        </Box>
      </SideMenuHeader>
      <SideMenuBody>
        <SideMenuItemGroup borderBottom="solid 2px">
          <SideMenuItemGroupTitle>期間</SideMenuItemGroupTitle>
          <Box display="flex" marginBottom="10px">
            <BusinessYearSelector />
            <DownloadButtonCSV />
          </Box>
        </SideMenuItemGroup>
        <SideMenuItemGroup borderBottom="solid 2px">
          <SideMenuItemGroupTitle>部署</SideMenuItemGroupTitle>
          <Box display="flex" marginBottom="10px">
            <DepartmentSelector />
          </Box>
        </SideMenuItemGroup>
        <SideMenuItemGroup>
          <SideMenuItemGroupTitle>実績</SideMenuItemGroupTitle>
          <Box display="flex" marginBottom="10px">
            <IndicatorGroupSelector />
          </Box>
        </SideMenuItemGroup>
      </SideMenuBody>
      <SideMenuFooter>
        <SideMenuItemGroup>
          <UserInfo />
        </SideMenuItemGroup>
        <SideMenuItemGroup>
          <Logout />
        </SideMenuItemGroup>
      </SideMenuFooter>
    </SideMenuContainer>
  );
};
