import {createAsyncThunk, createSlice, SerializedError} from '@reduxjs/toolkit';
import {
  fetchIndicatorsDepartmentValues,
  DepartmentIndicators,
  Indicator as IndicatorAPI,
  IndicatorPeriod as IndicatorPeriodAPI,
  IndicatorValue as IndicatorValueAPI,
  IndicatorValueWithIds as IndicatorValueWithIdsAPI,
  IndicatorPeriodWithIds as IndicatorPeriodWithIdsAPI,
} from '../../services/accesser/web-api-access.indicators.department-values';
import {fetchIndicatorsDepartmentsMemberValues} from '../../services/accesser/web-api-access.indicators.departments.member-values';

export type IndicatorValue = IndicatorValueAPI;

export type IndicatorPeriod = IndicatorPeriodAPI;

export type IndicatorValueWithIds = IndicatorValueWithIdsAPI;

export type IndicatorPeriodWithIds = IndicatorPeriodWithIdsAPI;

export type Indicator = IndicatorAPI;

export interface IndicatorValueData {
  [roleCode: string]: {
    items: {[itemId: number]: Indicator};
    searchUrl: string | null;
  };
}

export interface IndicatorValueState {
  error: SerializedError | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  data: IndicatorValueData;
}

const initialState: IndicatorValueState = {
  error: null,
  status: 'idle',
  data: {},
};

const updateStateWithData = (
  payload: DepartmentIndicators
): IndicatorValueData => {
  const newData: IndicatorValueData = {};

  payload.forEach(({roleCode, itemId, indicator, searchUrl}) => {
    if (!newData[roleCode]) {
      newData[roleCode] = {
        items: {},
        searchUrl,
      };
    }

    newData[roleCode].items[itemId] = indicator;
  });

  return newData;
};

export const fetchIndicatorDepartmentValues = createAsyncThunk(
  'indicatorValue/fetchDepartmentData',
  async ({year, indicatorIds}: {year: number; indicatorIds: string[]}) => {
    return await fetchIndicatorsDepartmentValues(year, indicatorIds);
  }
);

export const fetchIndicatorDepartmentMemberValues = createAsyncThunk(
  'indicatorValue/fetchMembersData',
  async ({
    departmentId,
    year,
    indicatorIds,
  }: {
    departmentId: number;
    year: number;
    indicatorIds: string[];
  }) => {
    return await fetchIndicatorsDepartmentsMemberValues(
      departmentId,
      year,
      indicatorIds
    );
  }
);

const indicatorValueSlice = createSlice({
  name: 'indicatorValue',
  initialState,
  reducers: {
    initializeIndicatorValue(state) {
      state.status = initialState.status;
      state.error = initialState.error;
      state.data = initialState.data;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchIndicatorDepartmentValues.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchIndicatorDepartmentValues.fulfilled, (state, action) => {
        state.data = updateStateWithData(action.payload);
        state.status = 'succeeded';
      })
      .addCase(fetchIndicatorDepartmentValues.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      })
      .addCase(fetchIndicatorDepartmentMemberValues.pending, state => {
        state.status = 'loading';
      })
      .addCase(
        fetchIndicatorDepartmentMemberValues.fulfilled,
        (state, action) => {
          state.data = updateStateWithData(action.payload);
          state.status = 'succeeded';
        }
      )
      .addCase(
        fetchIndicatorDepartmentMemberValues.rejected,
        (state, action) => {
          state.status = 'failed';
          state.error = action.error;
        }
      );
  },
});

export const {initializeIndicatorValue} = indicatorValueSlice.actions;

export default indicatorValueSlice.reducer;
