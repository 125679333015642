import {ErrorBoundary as ReactErrorBoundary} from 'react-error-boundary';
import {useNavigate} from 'react-router-dom'; // <-- 変更

const ErrorFallback = ({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: () => void;
}) => {
  const navigate = useNavigate(); // <-- 変更

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button
        onClick={() => {
          resetErrorBoundary();
          navigate('/'); // <-- リダイレクト
        }}
      >
        Try again
      </button>
    </div>
  );
};

export const ErrorBoundary = ({children}: {children: React.ReactNode}) => {
  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app here
      }}
    >
      {children}
    </ReactErrorBoundary>
  );
};
