import React from 'react';
import {IndicatorData} from '../../app/slices/indicatorViewSlice';
import Collapse from '@mui/material/Collapse';
import {
  Table,
  TableBody,
  TableRowHover,
  TableCellDepartment,
  TableCellIndicatorParentTop,
  TableCellIndicatorParentMiddle,
  TableCellIndicatorChildTop,
  TableCellIndicatorChildMiddle,
} from '../table/Table';
import {IndicatorCellData} from './IndicatorCellData';
import {IndicatorCellDataWithUnit} from './IndicatorCellDataWithUnit';
import {DropDownIcon} from './DropDownIcon';

export interface IndicatorTableChildProps {
  indicatorDatas: IndicatorData[];
  searchUrl?: string | null;
  name: string;
  nameParent?: string;
}

export const IndicatorTableChild: React.FC<IndicatorTableChildProps> = ({
  indicatorDatas,
  searchUrl,
  name,
  nameParent,
}) => {
  const [open, setOpen] = React.useState(false);

  const firstIndicatorData = indicatorDatas[0];

  if (!firstIndicatorData) {
    return null;
  }

  return (
    <div>
      <Table>
        <TableBody>
          <TableRowHover>
            {nameParent ? (
              <TableCellIndicatorParentTop rowSpan={3}>
                {nameParent}
              </TableCellIndicatorParentTop>
            ) : (
              <TableCellIndicatorParentMiddle rowSpan={3} />
            )}
            <TableCellIndicatorChildTop rowSpan={3}>
              {name}
            </TableCellIndicatorChildTop>
            <TableCellDepartment rowSpan={3}>
              <DropDownIcon open={open} onClick={() => setOpen(!open)} />
              {firstIndicatorData.name}
            </TableCellDepartment>
            <IndicatorCellData
              label={'前期'}
              month={firstIndicatorData.previous.month}
              quarter={firstIndicatorData.previous.quarter}
              total={firstIndicatorData.previous.total}
              searchUrl={searchUrl}
            />
          </TableRowHover>
          <TableRowHover>
            <IndicatorCellData
              label={'今期'}
              month={firstIndicatorData.current.month}
              quarter={firstIndicatorData.current.quarter}
              total={firstIndicatorData.current.total}
              searchUrl={searchUrl}
            />
          </TableRowHover>
          <TableRowHover>
            <IndicatorCellDataWithUnit
              label={'前年比'}
              month={firstIndicatorData.yearlyGrowth.month}
              quarter={firstIndicatorData.yearlyGrowth.quarter}
              total={firstIndicatorData.yearlyGrowth.total}
            />
          </TableRowHover>
        </TableBody>
      </Table>
      <Collapse in={open} unmountOnExit>
        <Table>
          <TableBody>
            {indicatorDatas.slice(1).map((indicatorData, index) => {
              const isFirst = index === 0;
              return (
                <React.Fragment key={indicatorData.itemId}>
                  <TableRowHover>
                    {isFirst && (
                      <>
                        <TableCellIndicatorParentMiddle
                          rowSpan={3 * indicatorDatas.length}
                        />
                        <TableCellIndicatorChildMiddle
                          rowSpan={3 * indicatorDatas.length}
                        />
                      </>
                    )}
                    <TableCellDepartment rowSpan={3}>
                      {indicatorData.name}
                    </TableCellDepartment>
                    <IndicatorCellData
                      label={'前期'}
                      month={indicatorData.previous.month}
                      quarter={indicatorData.previous.quarter}
                      total={indicatorData.previous.total}
                      searchUrl={searchUrl}
                    />
                  </TableRowHover>
                  <TableRowHover>
                    <IndicatorCellData
                      label={'今期'}
                      month={indicatorData.current.month}
                      quarter={indicatorData.current.quarter}
                      total={indicatorData.current.total}
                      searchUrl={searchUrl}
                    />
                  </TableRowHover>
                  <TableRowHover>
                    <IndicatorCellDataWithUnit
                      label={'前年比'}
                      month={indicatorData.yearlyGrowth.month}
                      quarter={indicatorData.yearlyGrowth.quarter}
                      total={indicatorData.yearlyGrowth.total}
                    />
                  </TableRowHover>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </Collapse>
    </div>
  );
};
