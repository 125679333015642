import {createAsyncThunk, createSlice, SerializedError} from '@reduxjs/toolkit';
import {doWebApiLogout as doLogout} from '../../services/accesser/web-api-access.logout';

export interface WebApiLogoutState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: SerializedError | null;
}

const initialState: WebApiLogoutState = {
  status: 'idle',
  error: null,
};

export const doWebApiLogout = createAsyncThunk(
  'webApiLogout/doWebApiLogout',
  async () => {
    await doLogout();
  }
);

const webApiLogoutSlice = createSlice({
  name: 'webApiLogout',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(doWebApiLogout.pending, state => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(doWebApiLogout.fulfilled, state => {
        state.status = 'succeeded';
      })
      .addCase(doWebApiLogout.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      });
  },
});

export default webApiLogoutSlice.reducer;
