import {createSlice, PayloadAction, SerializedError} from '@reduxjs/toolkit';

export interface AppLoadState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: SerializedError | null;
}

const initialState: AppLoadState = {
  status: 'idle',
  error: null,
};

const appLoadSlice = createSlice({
  name: 'appLoad',
  initialState,
  reducers: {
    setStatus(state, action: PayloadAction<AppLoadState['status']>) {
      state.status = action.payload;
    },
    setError(state, action: PayloadAction<SerializedError | null>) {
      state.error = action.payload;
    },
  },
});

export const {setStatus, setError} = appLoadSlice.actions;

export default appLoadSlice.reducer;
