export const STYLE_CONSTANTS = {
  bgColorTitle: '#005faa',
  bgColorData: '#ffffff',
  bgColorDataHover: '#ffefc1',

  fColorTitle: '#ffffff',
  fColorData: '#000000',

  border: '2px solid #cfcfcf',

  cellHeight: '30px',

  titleWidth: '350px',
  indicatorWidth: '250px',
  indicatorParentWidth: '125px',
  indicatorChildWidth: '125px',
  departmentWidth: 100,
  dataWidth: 68,
};
