import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface DataGridRowTermState {
  isViewAll: {[roleCd: string]: boolean};
}

const initialState: DataGridRowTermState = {
  isViewAll: {},
};

const dataGridRowTermSlice = createSlice({
  name: 'dataGridRowTerm',
  initialState,
  reducers: {
    setIsViewAll: (
      state,
      action: PayloadAction<{roleCd: string; value: boolean}>
    ) => {
      state.isViewAll[action.payload.roleCd] = action.payload.value;
    },
  },
});

export const {setIsViewAll} = dataGridRowTermSlice.actions;

export default dataGridRowTermSlice.reducer;
