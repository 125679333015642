import React from 'react';
import {BusinessDayViewData} from '../../app/slices/businessDayViewSlice';
import {TableEnclosure} from '../table/Table';
import {BusinessDayTableHead} from './BusinessDayTableHead';
import {BusinessDayTableBody} from './BusinessDayTableBody';

export interface BusinessDayTableProps {
  businessDayViewData: BusinessDayViewData;
}

export const BusinessDayTable = ({
  businessDayViewData,
}: BusinessDayTableProps): JSX.Element => {
  return (
    <TableEnclosure>
      <BusinessDayTableHead />
      <BusinessDayTableBody businessDayViewData={businessDayViewData} />
    </TableEnclosure>
  );
};
