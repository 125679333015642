import {useEffect} from 'react';
import {getConfig} from '../config';
import {Loading} from './Loading';

const {insightsUrl, portersAppId, portersApiUrl} = getConfig();

export const PortersLogin = () => {
  useEffect(() => {
    const redirectURL = encodeURIComponent(`${insightsUrl}/login`);
    const responseType = 'code';
    const scope = 'partition_r,user_r';
    const oauthURL = `${portersApiUrl}/v1/oauth?app_id=${portersAppId}&redirect_url=${redirectURL}&response_type=${responseType}&scope=${scope}`;
    window.location.href = oauthURL;
  }, []);

  return <Loading />;
};
