import {configureStore} from '@reduxjs/toolkit';
import appLoadReducer from './slices/appLoadSlice';
import authReducer from './slices/authSlice';
import businessDayViewReducer from './slices/businessDayViewSlice';
import businessDayReducer from './slices/businessDaySlice';
import businessYearReducer from './slices/businessYearSlice';
import dataGridRowTermReducer from './slices/dataGridRowTermSlice';
import departmentReducer from './slices/departmentSlice';
import hoveredCellReducer from './slices/hoveredCellSlice';
import indicatorGroupReducer from './slices/indicatorGroupSlice';
import indicatorHeaderReducer from './slices/indicatorHeaderSlice';
import indicatorTitleReducer from './slices/indicatorTitleSlice';
import indicatorValueReducer from './slices/indicatorValueSlice';
import indicatorViewReducer from './slices/indicatorViewSlice';
import memberReducer from './slices/memberSlice';
import userInfoReducer from './slices/userInfoSlice';
import webApiLoginReducer from './slices/webApiLoginSlice';
import webApiLogoutReducer from './slices/webApiLogoutSlice';

export const store = configureStore({
  reducer: {
    appLoad: appLoadReducer,
    auth: authReducer,
    businessDayView: businessDayViewReducer,
    businessDay: businessDayReducer,
    businessYear: businessYearReducer,
    dataGridRowTerm: dataGridRowTermReducer,
    department: departmentReducer,
    hoveredCell: hoveredCellReducer,
    indicatorGroup: indicatorGroupReducer,
    indicatorTitle: indicatorTitleReducer,
    indicatorHeader: indicatorHeaderReducer,
    indicatorValue: indicatorValueReducer,
    indicatorView: indicatorViewReducer,
    member: memberReducer,
    userInfo: userInfoReducer,
    webApiLogin: webApiLoginReducer,
    webApiLogout: webApiLogoutReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
