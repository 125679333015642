import iconv from 'iconv-lite';
import {IndicatorHeaderData} from '../app/slices/indicatorHeaderSlice';
import {IndicatorViewData} from '../app/slices/indicatorViewSlice';
import {
  IndicatorPeriod,
  IndicatorValue,
} from '../app/slices/indicatorValueSlice';

const CSV_SEPARATOR = ',';
const NEW_LINE = '\n';

const pushCsvRows = (
  nameParent: string,
  nameChild: string,
  nameItem: string,
  current: IndicatorPeriod,
  previous: IndicatorPeriod,
  yearlyGrowth: IndicatorPeriod
) => {
  return [
    createCsvRow(
      nameParent,
      nameChild,
      nameItem,
      '前期',
      extractValues(previous.month),
      extractValues(previous.quarter)
    ),
    createCsvRow(
      nameParent,
      nameChild,
      nameItem,
      '今期',
      extractValues(current.month),
      extractValues(current.quarter)
    ),
    createCsvRow(
      nameParent,
      nameChild,
      nameItem,
      '前年比',
      extractValues(yearlyGrowth.month, true),
      extractValues(yearlyGrowth.quarter, true)
    ),
  ];
};

const createCsvRow = (
  nameParent: string,
  nameChild: string,
  nameItem: string,
  term: string,
  monthValues: (number | string)[],
  quarterValues: (number | string)[]
): string => {
  return [
    nameParent,
    nameChild,
    nameItem,
    term,
    ...monthValues,
    ...quarterValues,
  ].join(CSV_SEPARATOR);
};

const extractValues = (indicator: IndicatorValue[], isPercentage = false) => {
  return indicator.map(({value}) => (isPercentage ? `${value}%` : value));
};

export const generateCsvData = (
  indicatorHeaderData: IndicatorHeaderData | undefined,
  indicatorViewData: IndicatorViewData[]
): Blob => {
  const headerRow = [
    '指標-1',
    '指標-2',
    '部署',
    '前/今期',
    ...(indicatorHeaderData?.month.title || []),
    ...(indicatorHeaderData?.quarter.title || []),
  ].join(CSV_SEPARATOR);

  const dataRows: string[] = [];

  indicatorViewData.forEach(({indicatorCluster}) => {
    indicatorCluster.forEach(({indicatorParents}) => {
      indicatorParents.forEach(
        ({name: nameParent, data: dataParent, indicatorChildren}) => {
          dataParent.forEach(
            ({name: nameItem, current, previous, yearlyGrowth}) => {
              dataRows.push(
                ...pushCsvRows(
                  nameParent,
                  '',
                  nameItem,
                  current,
                  previous,
                  yearlyGrowth
                )
              );
            }
          );
          indicatorChildren.forEach(({name: nameChild, data: dataChild}) => {
            dataChild.forEach(
              ({name: nameItem, current, previous, yearlyGrowth}) => {
                dataRows.push(
                  ...pushCsvRows(
                    nameParent,
                    nameChild,
                    nameItem,
                    current,
                    previous,
                    yearlyGrowth
                  )
                );
              }
            );
          });
        }
      );
    });
  });

  const csvData = [headerRow, ...dataRows].join(NEW_LINE);
  const buffer = iconv.encode(csvData, 'UTF-8');
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);

  return new Blob([bom, buffer], {type: 'text/csv'});
};
