import React from 'react';
import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {styled} from '@mui/system';
import {RootState, AppDispatch} from '../app/store';
import {fetchBusinessDays} from '../app/slices/businessDaySlice';
import {
  setBusinessDayViewData,
  startLoading,
  hasError,
} from '../app/slices/businessDayViewSlice';
import {Loading} from './Loading';
import {STYLE_CONSTANTS} from './table/constants';
import {BusinessDayTable} from './businessDayTable/BusinessDayTable';

export const DataTableFrame = styled('div')({
  padding: '10px 0px 20px 35px',
  boxShadow:
    '0px 5px 5px 0px rgb(0 0 0 / 10%), inset 0px 5px 5px 0px rgb(0 0 0 / 10%)',
  marginBottom: '20px',
  backgroundColor: STYLE_CONSTANTS.bgColorData,
});

export const BusinessDayView = (): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();
  const {businessDayView, businessDaysData, selectedYear} = useSelector(
    (state: RootState) => ({
      businessDayView: state.businessDayView,
      businessDaysData: state.businessDay.data,
      selectedYear: state.businessYear.selectedYear,
    })
  );

  useEffect(() => {
    if (selectedYear) {
      dispatch(startLoading());
      dispatch(fetchBusinessDays(selectedYear))
        .unwrap()
        .then(result => {
          dispatch(setBusinessDayViewData(result));
        })
        .catch(error => {
          dispatch(hasError(error));
        });
    }
  }, [dispatch, selectedYear]);

  useEffect(() => {
    if (selectedYear) {
      dispatch(setBusinessDayViewData(businessDaysData));
    }
  }, [dispatch, businessDaysData]);

  const {data, status, error} = businessDayView;

  if (status === 'failed' && error) {
    throw new Error(error.message || 'Unknown error');
  }

  if (status === 'loading') {
    return <Loading />;
  }

  return (
    <DataTableFrame>
      <BusinessDayTable businessDayViewData={data} />
    </DataTableFrame>
  );
};
