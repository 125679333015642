// import { MobileFieldDate, MobileFieldDatetime, MobileFieldAge } from '../../store/mobile-field-store';
import {
  dateFormat,
  dateTimeFormat,
  DateType,
  DateTypeEnum,
  secondFormat,
} from './date.consts';

/**
 * Fill in the specified number of digits with zeros.
 * @param str - String filled with zeros
 * @param padding - number of digits or characters
 * @returns Result of filling in with zeros
 */
export const zeroPad = (str: number, padding: number): string => {
  let strToZero = String(str) + '';
  while (strToZero.length < padding) {
    strToZero = '0' + strToZero;
  }

  return strToZero;
};

/**
 * Convert date to format_topadding
 * @param value - Date
 * @param format - date format
 * @returns Formatting results
 */
export const formatString_topadding = (value: Date, format: string): string => {
  const data: DateType = {
    y: value.getFullYear().toString(),
    m: zeroPad(value.getMonth() + 1, 2),
    d: zeroPad(value.getDate(), 2),
    h: zeroPad(value.getHours(), 2),
    i: zeroPad(value.getMinutes(), 2),
    s: zeroPad(value.getSeconds(), 2),
    ms: zeroPad(value.getMilliseconds(), 3),
  };
  return format.replace(
    /%([a-z]+)/g,
    (all: string, group: DateTypeEnum): string => {
      return data[group];
    }
  );
};

/**
 * Convert date to format
 * @param value - Date
 * @param format - date format
 * @returns Formatting results
 */
export const formatString = (value: Date, format: string): string => {
  const data: DateType = {
    y: value.getFullYear().toString(),
    m: (value.getMonth() + 1).toString(),
    d: value.getDate().toString(),
    h: value.getHours().toString(),
    i: value.getMinutes().toString(),
    s: value.getSeconds().toString(),
    ms: value.getMilliseconds().toString(),
  };
  return format.replace(
    /%([a-z]+)/g,
    (all: string, group: DateTypeEnum): string => {
      return data[group];
    }
  );
};

/**
 * Convert date to format
 * @param value - Date
 * @returns Formatting results
 */
export const formatDate = (value: string): string => {
  const date = new Date(value);
  const format = dateFormat;

  return formatString(date, format);
};

/**
 * Format date
 * @param value - Date data
 * @param includeSeconds - Seconds included
 * @returns Date string
 */
export const formatDateTime = (
  value: string,
  includeSeconds: boolean
): string => {
  const date = new Date(value);
  let format = dateTimeFormat;
  if (includeSeconds) {
    format += secondFormat;
  }

  return formatString(date, format);
};

/**
 * Format date to age date
 * @param date - Age date data
 * @returns Age date string
 */
export const getAge = (date: Date): number => {
  const today = new Date();
  let age = today.getFullYear() - date.getFullYear();
  const birthday = new Date(
    today.getFullYear(),
    date.getMonth(),
    date.getDate()
  );

  if (today < birthday) {
    age--;
  }

  return age;
};
