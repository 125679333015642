import {getWebApi} from './web-api-access';
import {getConfig} from '../../config';

const {insightsApiUrl} = getConfig();

export interface IndicatorsGroupsResponse {
  name: string;
  roleCode: string;
  order: number;
}

const constructIndicatorsGroupsURL = (): string => {
  return `${insightsApiUrl}/api/indicators/groups`;
};

export const fetchIndicatorsGroups = async (): Promise<
  IndicatorsGroupsResponse[]
> => {
  try {
    const url = constructIndicatorsGroupsURL();
    const response = await getWebApi(url, {
      credentials: 'include',
    });
    const body = await response.json();
    return body as IndicatorsGroupsResponse[];
  } catch (error) {
    console.error('Fetch indicators groups data failed:', error);
    throw error;
  }
};
