import {getWebApi} from './web-api-access';
import {getConfig} from '../../config';

const {insightsApiUrl} = getConfig();

export interface DepartmentsMembersResponse {
  itemId: number;
  name: string;
}

const constructDepartmentsMembersURL = (departmentId: string): string => {
  return `${insightsApiUrl}/api/departments/${departmentId}/members`;
};

export const fetchDepartmentsMembers = async (
  departmentId: string
): Promise<DepartmentsMembersResponse[]> => {
  try {
    const url = constructDepartmentsMembersURL(departmentId);
    const response = await getWebApi(url, {
      credentials: 'include',
    });
    const body = await response.json();
    return body as DepartmentsMembersResponse[];
  } catch (error) {
    console.error(
      `Fetch members for department ID ${departmentId} failed:`,
      error
    );
    throw error;
  }
};
